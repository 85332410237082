/**
 * Log-Eintrag, der in der Feedback-Liste auftaucht
 */
export interface Log {
  /**
   * ID
   */
  id: number
  /**
   * Stoffname
   */
  name: string
  /**
   * Daten zu möglichen Problemen
   */
  message: Record<string, string | number>[]
  /**
   * Code für erfolgreichen Import oder nicht
   */
  statusCode: number
  /**
   * Name der Datei, aus welcher der Gefahrstoff stammt
   */
  filename?: string
  /**
   * Daten als String, die an das Backend gesendet werden
   */
  data: string
}

/**
 * Einstellungen für das Filtern der Feedbackliste
 */
export interface FilterSetting {
  /**
   * ID
   */
  id: number
  /**
   * Kategorie von Imports ein- oder ausgeblendet
   */
  setting: boolean
  /**
   * Sprachstring
   */
  languageKey: string
}

/**
 * Interface für XML files
 */
export interface XmlFile {
  /**
   * XML-daten
   */
  xml: string
  /**
   * Namen der XML-Dateien
   */
  fileName: string
}

export enum FileType {
  Excel = 2,
  XML = 1,
  NONE = 0
}
