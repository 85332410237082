
import { Component, Vue } from 'vue-property-decorator'
import HazardousSubstancesImport from '@/components/HazardousSubstancesImport/HazardousSubstancesImport.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Gefahrstoffimport
 */
@Component({
  components: {
    HazardousSubstancesImport,
    BaseFrame
  }
})
export default class Substanceimport extends Vue {}
