
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as PDF from 'pdfjs-dist/legacy/build/pdf'
import { getApp } from '@/helpers/feathers'
import readXlsxFile from 'read-excel-file'
import { namespace } from 'vuex-class'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import {
  Log,
  FilterSetting,
  FileType,
  XmlFile
} from './HazardousSubstancesImport.schema'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pdfWorker = require('pdfjs-dist/build/pdf.worker.entry')
const NotificationStore = namespace('notification')

/**
 * Import von Gefahrstoffen als PDF- oder Excel-Datei
 */
@Component({})
export default class HazardousSubstancesImport extends Vue {
  /**
   * Eingelesene PDF-Datei(en)
   */
  public pdfFiles: File[] = []

  /**
   * Eingelesene Excel-Datei(en)
   */
  public excelFile: File | null = null

  /**
   * Name der eingelesenen Excel-Datei
   */
  public excelFileName = ''

  /**
   * Ausgelesene XMLDatei(en)
   */
  public xmlFiles: XmlFile[] = []

  /**
   * Ausgelesene Excel-Daten
   */
  public excelStrings: string[] = []

  /**
   * Zeigt an, ob eine oder mehrere der eingefügten PDFs
   * keinen gültigen XML-Anhang haben
   */
  public noXML = false

  /**
   * Feedback vom Upload
   */
  public logs: Log[] = []

  /**
   * Gefiltertes Feedback vom Upload
   */
  public filteredLogs: Log[] = []

  /**
   * Einstellungen zum Filtern der Feedback-Listen
   */
  public filterSettings: FilterSetting[] = [
    {
      id: 0,
      setting: true,
      languageKey: 'substanceImport.feedbackList.successfulImports'
    },
    {
      id: 1,
      setting: true,
      languageKey: 'substanceImport.feedbackList.criticalImports'
    },
    {
      id: 2,
      setting: true,
      languageKey: 'substanceImport.feedbackList.failedImports'
    }
  ]

  /**
   * Dateityp der aktuell hochgeladenen Datei(en)
   */
  public fileType: FileType = FileType.NONE

  /**
   * Fortschritt in % beim Dateiupload
   */
  public progress = 0

  /**
   * Togglet einen Toaster und zeigt diesen an
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Validationsregel für den PDF Upload.
   *
   * @returns Array mit Validationsfunktionen
   */
  public get pdfRules(): ((files: File[]) => string | true)[] {
    return [
      (files: File[]): string | true => {
        let correctType = true
        for (const file of files) {
          if (file.type !== 'application/pdf') {
            correctType = false
          }
        }

        if (this.pdfFiles.length > 0 && this.noXML) {
          return this.$t('substanceImport.importForm.noXML').toString()
        }
        return (
          correctType ||
          this.$t('substanceImport.importForm.wrongType').toString()
        )
      }
    ]
  }

  /**
   * Validationsregel für den Excel Upload.
   *
   * @returns Array mit Validationsfunktionen
   */
  public get excelRules(): ((file: File) => string | true)[] {
    return [
      (file: File): string | true => {
        this.excelStrings = []
        if (
          file &&
          file.type !==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
          file.type !== 'application/vnd.ms-excel'
        ) {
          return this.$t('substanceImport.importForm.wrongType').toString()
        }
        if (file) {
          readXlsxFile(file).then(rows => {
            // Indexstartwert bei 2, da Titel und Spaltenüberschrifen nicht verarbeitet werden sollen
            for (let i = 2; i < rows.length; ++i) {
              this.excelStrings.push(JSON.stringify(rows[i]))
            }
            this.excelFileName = file.name
          })
        }
        return true
      }
    ]
  }

  /**
   * Extrahiert die XML aus der PDF
   *
   * @param file - Eingelesene Datei
   * @returns - True oder False, je nachdem ob eine XML gefunden wurde
   */
  public pdfFileToXml(file: File): Promise<boolean> {
    try {
      const fr = new FileReader()
      return new Promise(resolve => {
        fr.onload = async (): Promise<void> => {
          PDF.GlobalWorkerOptions.workerSrc = pdfWorker
          // Datei als Arraybuffer einlesen
          const typedArray = new Uint8Array(fr.result as ArrayBuffer)
          // PDF Dokument auslesen
          const pdffile = PDF.getDocument(typedArray)
          const pdfDocument = await pdffile.promise

          // Anhänge aus PDF-Datei auslesen
          try {
            const attachments = await pdfDocument.getAttachments()
            if (!attachments) {
              resolve(false)
              return
            }
            // Für alle Anhänge wird ein File-Objekt angelegt
            for (const key of Object.keys(attachments)) {
              const filename = attachments[key].filename
              const contentBuffer = attachments[key].content.buffer
              const xmlFile = new File([contentBuffer], filename, {
                type: 'application/xml'
              })
              // Inhalt der XML-Datei wird als String ausgelesen
              const reader = new FileReader()
              reader.readAsText(xmlFile)
              reader.onload = () => {
                this.xmlFiles.push({
                  xml: reader.result as string,
                  fileName: filename
                })
                resolve(true)
              }
            }
          } catch (error) {
            const msg = this.$t(
              'substanceImport.importForm.extractingAttachmentsFailed'
            ).toString()
            this.toggleToast({
              text: msg,
              type: 'error'
            })
            throw error
          }
        }
        fr.readAsArrayBuffer(file)
      })
    } catch (error) {
      const msg = this.$t(
        'substanceImport.importForm.processPdfFailed'
      ).toString()
      this.toggleToast({
        text: msg,
        type: 'error'
      })
      throw error
    }
  }

  /**
   * Schickt den Inhalt einer XML-Datei an den Import Service
   *
   */
  public async importXML(): Promise<void> {
    let substances
    // Aktueller Dateityp ist jetzt XML
    this.fileType = FileType.XML
    // Liste mit den Logs leeren
    this.logs = []
    try {
      substances = await (await getApp())
        .service('substance-import')
        .create({ xmlFiles: this.xmlFiles })
    } catch (error) {
      const msg = this.$t(
        'substanceImport.importForm.xmlUploadFailed'
      ).toString()
      this.toggleToast({
        text: msg,
        type: 'error'
      })
      throw error
    }
    // Wenn der Gefahrstoff erfolgreich ohne Fehler an das Backend gesendet wurde, soll er in der Log-Liste erscheinen
    for (const substance of substances) {
      this.logs.push({
        id: Number(this.logs.length),
        name: substance.substanceObject?.tradeName,
        message: substance.logMessage,
        statusCode: substance.statusCode,
        data: substance.xml
      })
    }

    // Anpassen des Import-Fortschrittes
    this.progress = (this.logs.length / this.xmlFiles.length) * 100
    // Filter auf aktuelle Log-Liste anwenden
    this.filter()

    this.progress = 0
  }

  /**
   * Schickt den Inhalt einer XML-Datei an den Import Service
   *
   * @param rowID - ID der Log-Zeile, die erneut importiert werden soll
   */
  public async importXMLFromFeedbackList(rowID: number): Promise<void> {
    let substance
    // Aufrufe aus der Feedbackliste liefern rowID mit
    const index = this.logs.findIndex(x => x.id === rowID)
    const data = {
      xmlFiles: [
        {
          xml: this.logs[index].data
        }
      ],
      allowUpdate: true
    }
    try {
      substance = await (await getApp())
        .service('substance-import')
        .create(data)
    } catch (error) {
      const msg = this.$t(
        'substanceImport.importForm.xmlUploadFailed'
      ).toString()
      this.toggleToast({
        text: msg,
        type: 'error'
      })
      throw error
    }
    this.logs.splice(index, 1, {
      id: rowID,
      name: substance[0].substanceObject?.tradeName,
      message: substance[0].logMessage,
      statusCode: substance[0].statusCode,
      data: substance[0].xml
    })
    this.filter()
  }

  /**
   * Schickt den Inhalt einer Excel Zeile an den Import Service
   *
   */
  public async importExcel(): Promise<void> {
    let substances
    // Aktuellen Dateityp auf Excel setzen
    this.fileType = FileType.Excel
    // Log-Liste leeren
    this.logs = []
    const excelData = []
    for (let i = 0; i < this.excelStrings.length; ++i) {
      excelData.push({
        excel: this.excelStrings[i],
        row: i + 3
      })
    }
    try {
      substances = await (await getApp())
        .service('substance-import')
        .create({ excelData })
    } catch (error) {
      const msg = this.$t(
        'substanceImport.importForm.excelUploadFailed'
      ).toString()
      this.toggleToast({
        text: msg,
        type: 'error'
      })
      throw error
    }
    for (const substance of substances) {
      this.logs.push({
        // Wenn der Gefahrstoff erfolgreich an das Backend gesendet wurde, soll er in der Liste erscheinen
        id: Number(this.logs.length),
        name: substance.substanceObject?.tradeName,
        message: substance.logMessage,
        statusCode: substance.statusCode,
        filename: this.excelFileName,
        data: substance.excel
      })
      // Anpassen des Import-Fortschrittes
      this.progress = (this.logs.length / this.excelStrings.length) * 100
    }
    // Filter auf aktuelle Log-Liste anwenden
    this.filter()
    // Nach dem Senden aller Stoffe wird der Fortschrittsbalken zurückgesetzt
    this.progress = 0
  }

  /**
   * Schickt den Inhalt einer Excel Zeile an den Import Service
   *
   * @param rowID - ID der Excel Zeile aus der Feedbackliste
   */
  public async importExcelFromFeedbackList(rowID: number): Promise<void> {
    let substance
    const index = this.logs.findIndex(x => x.id === rowID)
    const excelData = [
      {
        excel: this.logs[index].data,
        row: rowID
      }
    ]
    try {
      substance = await (await getApp()).service('substance-import').create({
        excelData: excelData,
        allowUpdate: true
      })
    } catch (error) {
      const msg = this.$t(
        'substanceImport.importForm.excelUploadFailed'
      ).toString()
      this.toggleToast({
        text: msg,
        type: 'error'
      })
      throw error
    }
    this.logs.splice(index, 1, {
      id: rowID,
      name: substance[0].substanceObject?.tradeName,
      message: substance[0].logMessage,
      statusCode: substance[0].statusCode,
      filename: this.excelFileName,
      data: substance[0].excel
    })
    this.filter()
  }

  /**
   * Speichert Fehlerliste von Excellogs in einer Datei
   *
   * @param messageData - Text, der in eine Datei soll
   * @param name - Name des Stoffes; dient als Dateiname
   */
  public saveLog(
    messageData: Record<string, string | number>[],
    name: string
  ): void {
    let text = ''
    for (const element of messageData) {
      text += this.getLogMessage(element)
    }
    const anchor = document.createElement('a')
    anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    anchor.target = '_blank'
    anchor.download = name + '.txt'
    anchor.click()
  }

  /**
   * Speichert Fehlerliste von allen Excellogs in einer Datei
   */
  public saveAllLogs(): void {
    let text = ''
    for (const log of this.filteredLogs) {
      if (
        log.statusCode === 2 ||
        log.statusCode === 4 ||
        log.statusCode === 5
      ) {
        if (log.name) {
          text += '---' + log.name + '---\n'
        } else {
          text +=
            '---' +
            this.$t('substanceImport.feedbackList.unknownSubstance') +
            '---\n'
        }

        for (const m of log.message) {
          text += this.getLogMessage(m)
        }
        text += '\n'
      }
    }
    const anchor = document.createElement('a')
    anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    anchor.target = '_blank'
    anchor.download =
      'eplas-substanceimport-' +
      new Date().toISOString().split('T')[0] +
      '-' +
      new Date().getHours() +
      ':' +
      new Date().getMinutes() +
      ':' +
      new Date().getSeconds() +
      '-' +
      this.filteredLogs[0].filename +
      '.txt'
    anchor.click()
  }

  /**
   * Entfernt den entsprechenden Eintrag aus der Feedback-Liste, wenn in der UI auf den Löschen Button geklickt wurde
   *
   * @param id - ID des zu entfernenden Elements
   */
  public remove(id: number): void {
    this.logs = this.logs.filter(item => item.id !== id)
    this.filteredLogs = this.filteredLogs.filter(item => item.id !== id)
  }

  /**
   * Passt Liste an die Filtereinstellungen an
   */
  public filter(): void {
    this.filteredLogs = []
    // für alle Log-Einträge wird überprüft ob diese angezeigt werden sollen
    // falls ja, werden diese in das gefilterte Array eingetragen
    for (const element of this.logs) {
      if (
        this.filterSettings[0].setting &&
        (element.statusCode === 10 || element.statusCode === 11)
      ) {
        this.filteredLogs.push(element)
      }
      if (
        this.filterSettings[1].setting &&
        (element.statusCode === 2 ||
          element.statusCode === 3 ||
          element.statusCode === 5)
      ) {
        this.filteredLogs.push(element)
      }
      if (this.filterSettings[2].setting && element.statusCode === 4) {
        this.filteredLogs.push(element)
      }
    }
  }

  /**
   * Importiert/Aktualisiert alle importierbaren aber fehlerhaften Stoffe
   */
  public updateAll(): void {
    for (const log of this.logs) {
      if (
        log.statusCode === 2 ||
        log.statusCode === 3 ||
        log.statusCode === 5
      ) {
        if (this.fileType === 1) {
          this.importXMLFromFeedbackList(log.id)
        } else if (this.fileType === 2) {
          this.importExcelFromFeedbackList(log.id)
        }
      }
    }
  }

  /**
   * Löscht alle Einträge aus der Feedback-Liste
   */
  public clearAll(): void {
    this.logs = []
    this.filteredLogs = []
  }

  /**
   * Baut aus den empfangenen Daten (Zeile, Stoffname und Message-Nr) vom Excel-Import die Log-Message zusammen
   *
   * @param item - Informationen zu Log-Message
   * @returns - Log-Message als zusammenhängender String, übersetzt in die entsprechende Sprache
   */
  public getLogMessage(item: Record<string, string | number>): string {
    let message = ''
    message += this.$t('substanceImport.excelLogs.row') + ' ' + item.row + ': '
    switch (item.type) {
      case 0:
        message += this.$t('substanceImport.excelLogs.nameNotFound')
        break
      case 1:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidHStatement')
        break
      case 2:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.notExistingHStatement')
        break
      case 3:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidPStatement')
        break
      case 4:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.notExistingPStatement')
        break
      case 5:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidGHSSymbol')
        break
      case 6:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidCompositionState')
        break
      case 7:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidSignalword')
        break
      case 8:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidStorageClass')
        break
      case 9:
        message +=
          item.name + ' ' + this.$t('substanceImport.excelLogs.invalidUnNr')
        break
      case 10:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidWaterHazardClass')
        break
      case 11:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidBoilingPoint')
        break
      case 12:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidVaporPressure')
        break
      case 13:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidVaporPressureUnit')
        break
      case 14:
        message +=
          item.name +
          ' ' +
          this.$t('substanceImport.excelLogs.invalidPhysicalState')
        break
      default:
        message += ''
    }
    message += '\n'

    return message
  }

  /**
   * Wandelt den StatusCode in eine Status-Message um
   *
   * @param statusCode - Status Code
   * @returns - Status-Message
   */
  public getStatusMessage(statusCode: number): string {
    let msg = ''
    switch (statusCode) {
      case 10:
        msg +=
          this.$t('substanceImport.feedbackList.substance') +
          ' ' +
          this.$t('substanceImport.feedbackList.created')
        break
      case 11:
        msg +=
          this.$t('substanceImport.feedbackList.substance') +
          ' ' +
          this.$t('substanceImport.feedbackList.updated')
        break
      case 2:
        msg +=
          this.$t('substanceImport.feedbackList.warning') +
          ': ' +
          this.$t('substanceImport.feedbackList.invalidValues')
        break
      case 5:
        msg +=
          this.$t('substanceImport.feedbackList.warning') +
          ': ' +
          this.$t('substanceImport.feedbackList.invalidValues') +
          ' ' +
          this.$t('substanceImport.feedbackList.and') +
          ' ' +
          this.$t('substanceImport.feedbackList.substanceExisting')
        break
      case 3:
        msg +=
          this.$t('substanceImport.feedbackList.warning') +
          ': ' +
          this.$t('substanceImport.feedbackList.substanceExisting')
        break
      case 4:
        msg += this.$t('substanceImport.feedbackList.error')
        break
    }
    return msg
  }

  /**
   * Schaut ob ein Name für den Gefahrstoff vorhanden ist und gibt alternativ einen Standart Namen zurück
   *
   * @param name - Name des Gefahrstoffes der überprüft werden soll
   * @returns - Name, der in der Feedbackliste angezeigt werden soll
   */
  public getSubstanceName(name: string): string {
    if (name) {
      return name
    }
    return this.$t('substanceImport.feedbackList.unknownSubstance').toString()
  }

  /**
   * Überprüft, ob es in den Logs exportierbare Fehlermeldungen gibt
   *
   * @returns - Exportierbare Fehlermeldung vorhanden oder nicht
   */
  public checkLogs(): boolean {
    for (let i = 0; i < this.filteredLogs.length; ++i) {
      if (
        this.filteredLogs[i].statusCode === 2 ||
        this.filteredLogs[i].statusCode === 4 ||
        this.filteredLogs[i].statusCode === 5
      ) {
        return true
      }
    }
    return false
  }

  /**
   * Führt bei Änderung der Sprachauswahl die Validierung erneut durch,
   * um die Fehlermeldungen in der gewählten Sprache zu zeigen
   **/
  @Watch('$i18n.locale')
  private formValidate(): void {
    const formExcel = this.$refs.formExcel as HTMLFormElement
    const formPDF = this.$refs.formPDF as HTMLFormElement
    formExcel.validate()
    formPDF.validate()
  }

  /**
   * Watcher für PDF Dateien
   * Liest Anhänge von PDF Dateien für die Validierung und ruft diese erneut auf,
   * da hier erst die Infos über vorhandene XML Anhänge vorliegen
   */
  @Watch('pdfFiles')
  private async readXMLAttachments(): Promise<void> {
    this.xmlFiles = []
    this.noXML = false
    for (const pdfFile of this.pdfFiles) {
      if (!(await this.pdfFileToXml(pdfFile))) {
        this.noXML = true
      }
    }
    const form = this.$refs.formPDF as HTMLFormElement
    form.validate()
  }
}
